import React from 'react';

// import brands data
// import { brands } from '../data';

const Brands = () => {
  return (
    // <section className='min-h-[146px] bg-tertiary flex items-center'>
    //   <div
    //     className='container mx-auto flex md:justify-between items-center flex-wrap justify-evenly'
    //   >
    //     {brands.map((brand, idx) => {
    //       return (
    //         <div
    //           key={idx}
    //         >
    //           {/* <img src={brand.img} alt='' /> */}

    //         </div>
    //       );
    //     })}

    //   </div>
    // </section>
    <section className='min-h-[146px] bg-tertiary flex place-items-center'>
      <div  className='container mx-auto  flex flex-col 
                    items-center justify-center'>
      <p class="p-4 italic flex place-content-center text-right items-center">“Technology is the answer, but what was the question?”
                - Cedric Price
              </p>

      </div>
    </section>
  );
};

export default Brands;
