import React from 'react';

const Project = ({ item }) => {
  const handleDivClick = () => {
    if (item.name === 'Acroverse AI') {
      window.open('https://acroverse-ai.vercel.app/', '_blank');
    }
    if (item.name === 'Maze') {
      window.open('https://www.youtube.com/watch?v=jr_KU318ALI', '_blank');
    }
    if (item.name === 'Bolt Detection') {
      window.open('https://github.com/mr-rafiq/Object_detection_From_DWG', '_blank');
    }
  };

  return (
    <div key={item.id} className='flex flex-col items-center text-center cursor-pointer' onClick={handleDivClick}>
      <div className='mb-8'>
        <img className='rounded-2xl' src={item.image} alt='' />
      </div>
      <p className='capitalize text-accent text-sm mb-3'>{item.category}</p>
      <h3 className='text-2xl font-semibold capitalize mb-3'>{item.name}</h3>
      <p className='text-base max-w-md'>
        {/* Additional content goes here */}
      </p>
    </div>
  );
};

export default Project;