//  icons
import {
  FiYoutube,
  FiInstagram,
  FiGithub,
  FiDribbble,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
  FiLinkedin,
  FiDownload,
} from 'react-icons/fi';

import {
  GiTechnoHeart,
  GiAqueduct,
} from 'react-icons/gi';



// companies icons
import FreelancerBrandIcon from './assets/img/brands/freelancer.png';
import UpworkBrandIcon from './assets/img/brands/upwork.png';
import FiverBrandIcon from './assets/img/brands/fiverr.png';
import BehanceBrandIcon from './assets/img/brands/behance.png';
import DribbbleBrandIcon from './assets/img/brands/dribbble.png';

// projects images
import Project1 from './assets/img/projects/p1.gif';
import Project2 from './assets/img/projects/p2.gif';
import Project3 from './assets/img/projects/p3.gif';
import Project4 from './assets/img/projects/p4.gif';
import Project5 from './assets/img/projects/p5.jpg';
import Project6 from './assets/img/projects/p6.jpg';
import Project7 from './assets/img/projects/p7.jpg';
import Project8 from './assets/img/projects/p8.jpg';

// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg5 from './assets/img/skills/cs.png';
import SkillImg6 from './assets/img/skills/rhinoceros.png';
import SkillImg7 from './assets/img/skills/python.png';
import SkillImg8 from './assets/img/skills/unity.png';
import SkillImg9 from './assets/img/skills/git.png';
import SkillImg10 from './assets/img/skills/tensor.png';
import SkillImg11 from './assets/img/skills/opencv.png';
import SkillImg12 from './assets/img/skills/pytorch.png';

// testimonial images
import TestiImage1 from './assets/img/testimonials/testimonial-1.webp';
import TestiImage2 from './assets/img/testimonials/testimonial-2.webp';
import TestiImage3 from './assets/img/testimonials/testimonial-3.webp';





// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'services',
    href: 'services',
  },

  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiYoutube />,
    href: 'https://www.youtube.com/watch?v=jr_KU318ALI',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/rafiq.tec/',
  },
  {
    icon: <FiGithub />,
    href: 'https://github.com/mr-rafiq',
  },
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/in/mohamedrafiq04/',
  },  
  {
    icon: <FiDownload />,
    href: 'CV',
  },  

];



// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: '',
  },
  {
    img: UpworkBrandIcon,
    href: '',
  },
  {
    img: FiverBrandIcon,
    href: '',
  },
  {
    img: BehanceBrandIcon,
    href: '',
  },
  {
    img: DribbbleBrandIcon,
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'Maze',
    category: 'AI/ML',
  },
  {
    id: '8',
    image: Project8,
    name: 'Acroverse AI',
    category: 'AI/ML',
  },
  {
    id: '2',
    image: Project2,
    name: 'Walking Pavilion',
    category: 'Computational design',
  },
  {
    id: '3',
    image: Project3,
    name: 'Uniphorm',
    category: 'Computational design',
  },
  {
    id: '4',
    image: Project4,
    name: 'Robo Alucobond Fold',
    category: 'Computational design',
  },
  {
    id: '5',
    image: Project5,
    name: 'Bolt Detection',
    category: 'AI/ML',
  },
  {
    id: '7',
    image: Project7,
    name: 'Manageverse',
    category: 'Application development',
  },
  {
    id: '6',
    image: Project6,
    name: 'FrankyRhino',
    category: 'Application development',
  },

];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'AI/ML',
  },
  {
    name: 'Application development',
  },
  {
    name: 'Computational design',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
  {
    image: SkillImg9,
  },
  {
    image: SkillImg10,
  },
  {
    image: SkillImg11,
  },
  {
    image: SkillImg12,
  },
];

// services
export const services = [
  {
    icon: <GiAqueduct />,
    name: 'Architecture',
    description:
      "Architecture is a collaboration of space and light. It's how I spend my days designing spaces rooted in the earth.",
  },
  {
    icon: <FiSettings />,
    name: 'Computational Design',
    description:
      'I have developed various computational strategies for product workflow, such as design automation and data-driven manufacturing production.',
  },
  {
    icon: <FiLayout />,
    name: 'Application Development',
    description:
      'I specialize in developing custom applications tailored to specific needs and platforms, while also offering expertise in database integration and optimization to enhance performance.',
  },
  {
    icon: <GiTechnoHeart />,
    name: 'Machine Learning',
    description:
      'Allow me to assist you with your intricate challenges. I specialize in offering innovative solutions grounded in Machine Learning Algorithms, meticulously crafted to suit your businessAllow me to address your complex challenges. I specialize in crafting innovative solutions rooted in Machine Learning Algorithms, precisely customized to meet your business requirements.',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage2,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage3,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have a question?',
    subtitle: 'I am here to help you.',
    description: 'Email me at ar7rafiq@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Current Location',
    subtitle: 'Kleve, Germany',
    description: 'Serving clients worldwide',
  },
];


