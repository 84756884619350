import React from 'react';
// import icons
import { social } from '../data';
import cvpdf from '../assets/img/PDF/Mohamed_Rafiq_CV.pdf'

const Socials = () => {
  return (
    <ul className='flex space-x-6 '>
      {social.map((item, index) => {
      
        const download = () => {
          console.log(item.href);
          if(item.href == 'CV'){
           
              const pdfWindow = window.open("Mohamed_Rafiq_CV");
              const title     = "CV";
              const URI       = "CV/Mohamed_Rafiq";
              const html      = `
                <html>
                  <head>
                    <title>${title}</title>
                  </head>
                  <body style="margin:0">
                    <embed width="100%" height="100%" src=${cvpdf} type="application/pdf">
                  </body>
                </html>
              `;
          
              pdfWindow.document.write(html);
              pdfWindow.document.close();
              pdfWindow.history.pushState(null, null, URI);
          
          }

        }
        return (
          <li
            className='flex justify-center items-center text-accent'
            key={index}
          >
            <a className='text-base' href={item.href} onClick={download}  >
              {item.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Socials;
