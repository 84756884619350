import React from 'react';


// import woman image
import RafImg from '../assets/img/raf.png';
import BGif from '../assets/img/back.gif';
import Typical from 'react-typical';




const Hero = () => {
  return (
    <section
      id='home' 
      className='lg:h-[85vh] flex items-center bg-hero-pattern lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'
    >
      <div className='container mx-auto h-full'>
        <div className='flex items-center h-full pt-8'>
          <div className='flex-1 flex flex-col items-center lg:items-start'>
            <p className='text-2xl text-accent text-md mb-[22px]'>
              Hey, I'm Mohamed Rafiq! 
            </p>
            {/* <h1 className='text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]'>
              Designer | Programmer
            </h1> */}
            <Typical className='text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px]'
                steps={[
                  'AI Enthusiast',
                  2000,
                  'Architect',
                  2000,
                  'Web Developer',
                  2000,
                  'Computational Designer',
                  1000,
                ]}
                wrapper="h1"
                loop={Infinity}
              />
            <p className='pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left'>
            An enthusiastic professional designer is currently influencing architecture and design technology development by creating an application that is intuitive to use and encourages user involvement with data and information.
            </p>
            <button className='btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all'
            onClick={(e) => {
              e.preventDefault();
              window.location.href='mailto:ar7rafiq@gmail.com';
              }}>
              Work with me
            </button>
          </div>
          <div className='hidden lg:flex flex-1 justify-end items-end h-full'>
            {/* <img src={RafImg} alt='' /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
