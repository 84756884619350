import React from 'react';

// import img
import Image from '../assets/img/about1.webp';

const About = () => {
  return (
    <section className='section bg-secondary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl'
            src={Image}
            alt=''
          />
          <div className='flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Mohamed Rafiq
              </h2>
              <p className='mb-4 text-accent'>
              Multidisciplinary Designer
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
              Computational Designer expert with experience working in the architecture and facade 
industry. A passionate lover of artificial intelligence, fascinated by the why and how of 
design, pattern, and complexity, and a researcher on a quest to learn more about human 
personalities and discover new ways to understand ourselves. <br />
                <br /> Self-taught enthusiast in 
machine learning and artificial intelligence with an interest in high-impact applications 
including reinforcement learning. Proficient in a wide variety of interdisciplinary tools 
including  Java, C#, Rhinoceros with Grasshopper, robotics, and fabrication.

              </p>
            </div>
            <button className='btn btn-md bg-accent hover:bg-secondary-hover transition-all'
             onClick={(e) => {
              e.preventDefault();
              window.location.href='mailto:ar7rafiq@gmail.com';
              }}>
              Contact me
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
