import React, { useState, useEffect } from 'react';

// import data
import { projectsData } from '../data';
import { projectsNav } from '../data';

// import components
import Project from './Project';

// import  { Component } from "react";
// import { Router, Switch, Route } from "react-router-dom";
// import components
// import Projects from './Projects';
// import history from './History';  
// import Maze from "./ProjectsPage/Maze";
// import Uniphorm from "./ProjectsPage/Uniphorm";

// class Routes extends Component {
//   render() {
//       return (
//           <Router history={history}>
//               <Switch>
//                   <Route path="/" exact component={Maze} />
//                   <Route path="/Uniphorm" component={Uniphorm} />
//               </Switch>
//           </Router>
//       )
//   }
// }



const Projects = () => {
  const [item, setItem] = useState({ name: 'all' });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    // get projects based on item
    if (item.name === 'all') {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
    console.log(item.name);
  };

  return (
    <div>
      {/* projects nav */}
      <nav className='mb-12 max-w-xl mx-auto'>
        <ul className='flex flex-col md:flex-row justify-evenly items-center text-white'>
          {projectsNav.map((item, index) => {
            return (
              <li
                onClick={(e) => {
                  handleClick(e, index);
                }}
                className={`${
                  active === index ? 'active' : ''
                } cursor-pointer capitalize m-4`}
                key={index}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </nav>
      {/* projects */}
      <section className='grid gap-y-12 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-8'>
        {projects.map((item) => {
          return (<Project  item={item} key={item.id}  />);
        })}
      </section>
    </div>
  );
};

export default Projects;
